<template>
  <el-dialog
    class="add-leave-dialog"
    title="外出请假审批"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="820px"
    :before-close="cancel">
    <template v-if="init">
      <div id="leaveTemp">
        <div class="table-form-header" style=" text-align: center; padding-bottom: 40px; font-size: 20px;">
          <h3>广东南方职业学院学生请假审批表</h3>
        </div>
        <div class="table-form-content">
          <el-form ref="form" :model="formData" :rules="comptRules" style="padding-right: 0;">
            <table class="table" border="1"
                   style=" width: 100%; border-collapse: collapse; table-layout: fixed;font-size:15px;">
              <colgroup>
                <col style="width:120px;">
                <col>
                <col style="width:120px;">
                <col>
              </colgroup>
              <tr>
                <td class="font-right">请假人姓名</td>
                <td colspan="3">
                  <span>{{ formData.stuName }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-right">班级</td>
                <td>
                  <span>{{ formData.userInfo.className }}</span>
                </td>
                <td class="font-right">所在二级学院</td>
                <td>
                  <span>{{ formData.userInfo.collegeName }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-right">请假人电话</td>
                <td>
                  <span>{{ formData.userInfo.fixedPhone }}</span>
                </td>
                <td style="text-align: right">父母联系电话</td>
                <td>
                  <span v-if="formData.userInfo.fatherPhone">{{ formData.userInfo.fatherPhone }}</span>
                  <span v-else>{{ formData.userInfo.motherPhone }}</span>
                </td>
              </tr>
              <tr>
                <td style="text-align: right">请假时间</td>
                <td colspan="3">
                  {{ formData.beginTime | formatDate('YYYY年MM月DD日 HH:mm')
                  }}分 至 {{ formData.endTime | formatDate('YYYY年MM月DD日 HH:mm')
                  }}分（{{ calcuTimeLong(formData.timeLong)
                  }}）
                </td>
              </tr>
              <tr style="height: 50px;">
                <td style="text-align: right">请假原因</td>
                <td colspan="3">
                  {{ formData.leaveReason }}
                </td>
              </tr>
              <tr>
                <td colspan="4" style="padding: 0;">
                  <leaveTempPrint v-show="!getPermitStatus()||isPrint" :leave-data="formData" />
                  <leaveTempForm v-show="getPermitStatus()&&!isPrint" ref="formRef" :form-data="formData" />
                </td>
              </tr>
            </table>
            <p>
              注：1．本表上联由辅导员保存，下联交学校门卫登记。2．周一至周五上课期间，请假1天须辅导员审批；请假2-4天须副院长审批；请假5-7天须学生处审批；请假7天以上，须校领导审批。3．病假外出就医者须提供校医证明。4．凡请假外出回校者必须到辅导员处销假（病历复印件钉在本表后面）。</p>
          </el-form>
        </div>
      </div>
    </template>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button v-if="getPermitStatus()" type="primary" @click="save('请假申请',addLeave)">确 定
      </el-button>
      <el-button type="primary" plain icon="el-icon-printer" @click="printSignForm">打 印</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addLeave, getLeaveById } from '@/api/leave'
import { getStudentByUserId } from '@/api/student'
import leaveTempPrint from './leaveTempPrint.vue'
import leaveTempForm from './leaveTempForm.vue'
import dialog from '@/vue/mixins/dialog'
import { hasPermission } from '@/utils/common-js'
import { Decimal } from 'decimal.js'

export default {
  mixins: [dialog],
  components: {
    leaveTempForm,
    leaveTempPrint
  },
  props: {
    filterType: Number
  },
  data () {
    return {
      isPrint: false,
      formData: {},
      init: false,
      TEMPLATE_HTML_URL: `${process.env.BASE_URL}print/leaveTemp.html`
    }
  },
  computed: {
    comptRules () {
      let _rules = {}
      switch (this.filterType) {
        case 1:
          _rules = {
            teacherOpinion: {
              max: 200,
              message: '仅限200字',
              trigger: 'blur'
            },
            teacherStatus: {
              required: true,
              message: '请选择审批意见',
              trigger: 'blur'
            }
          }
          break
        case 2:
          _rules = {
            collegeOpinion: {
              max: 200,
              message: '仅限200字',
              trigger: 'blur'
            },
            collegeStatus: {
              required: true,
              message: '请选择审批意见',
              trigger: 'blur'
            }
          }
          break
        case 3:
          _rules = {
            stuOfficeOpinion: {
              max: 200,
              message: '仅限200字',
              trigger: 'blur'
            },
            stuOfficeStatus: {
              required: true,
              message: '请选择审批意见',
              trigger: 'blur'
            }
          }
          break
        case 4:
          _rules = {
            schOpinion: {
              max: 200,
              message: '仅限200字',
              trigger: 'blur'
            },
            schStatus: {
              required: true,
              message: '请选择审批意见',
              trigger: 'blur'
            }
          }
          break
      }
      return _rules
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.preTitle = '审批'
        this.getLeaveById()
      }
    })
  },
  methods: {
    addLeave, // 保存api
    // 根据id请假信息
    getLeaveById () {
      getLeaveById(this.id).then((res) => {
        this.formData = res.data
        this.formData.filterType = this.filterType
        if (res.data.userId) {
          this.getStudentByUserId(res.data.userId)
        }
      })
    },
    // 根据id获取学生信息
    getStudentByUserId (userId) {
      getStudentByUserId(userId).then(res => {
        this.formData.userInfo = res.data
        this.init = true
        this.setInitData()
      })
    },
    getPermitStatus () {
      const _permitKeys = ['leaveAudit:teacher', 'leaveAudit:college', 'leaveAudit:stuOffice', 'leaveAudit:sch']
      let _flag = false
      if (hasPermission(_permitKeys)) {
        switch (this.filterType) {
          case 1:
            _flag = !this.formData.teacherUserId
            break
          case 2:
            _flag = !this.formData.collegeUserId
            break
          case 3:
            _flag = !this.formData.stuOfficeUserId
            break
          case 4:
            _flag = !this.formData.schUserId
            break
        }
      }

      return _flag
    },
    /* 打印报名表单 */
    printSignForm () {
      this.isPrint = true
      if (this.isPrint) {
        const report = document.querySelector('#leaveTemp')
        const iframe = document.createElement('iframe')
        const iframeStyle =
          'border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;table{border-collapse: collapse;}'
        iframe.style.cssText = iframeStyle
        const id = new Date().getTime()
        iframe.id = id
        iframe.src = this.TEMPLATE_HTML_URL
        document.body.appendChild(iframe)
        iframe.onload = () => {
          iframe.contentDocument.querySelector('#leaveForm').innerHTML = this.nodeToString(report)
          setTimeout(() => {
            iframe.contentWindow.print()
          }, 1000)
          setTimeout(() => {
            iframe.remove()
            this.close()
          }, 1000)
        }
      }
    },
    nodeToString (node) {
      let tmpNode = document.createElement('div')
      tmpNode.appendChild(node.cloneNode(true))
      const str = tmpNode.innerHTML
      tmpNode = node = null
      return str
    },
    // 不足24小时的按小时显示，否则按天显示
    calcuTimeLong (day) {
      if (!day) return
      if (day && Number(day) < 1) {
        return new Decimal(Number(day)).mul(24) + '小时'
      } else {
        const _times = day.toString().split('.')
        if (Number(_times[1])) {
          return `${_times[0]}天${new Decimal(Number('0.' + _times[1])).mul(24)}小时`
        } else {
          return `${day}天`
        }
      }
    }
  }
}
</script>
<style lang="scss">
.add-leave-dialog {
  .el-dialog {
    margin-top: 5vh !important;

    #leaveTemp {
      .signature {
        margin-left: -110px;
        margin-right: 70px;
      }

      .signature-date {
        font-size: 14px;
      }

      .table-form-header {
        text-align: center;
        padding-bottom: 40px;
        font-size: 20px;
      }

      .table-form-content {
        .table {
          width: 100%;
          border-collapse: collapse;
          table-layout: fixed;

          tr {
            .textCenter {
              text-align: center;
            }

            .font-right {
              text-align: right;
            }

            .flex-between {
              display: flex;
              justify-content: space-between;
            }

            td {
              padding: 0 10px;
              font-size: 15px;
              line-height: 40px;
              height: 40px;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    .leave-temp.table {
      table-layout: fixed;

      td {
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
      }

      tr {
        td:last-child {
          border-right: 0;
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
