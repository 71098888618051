<template>
  <el-dialog
    class="verify-leave-dialog"
    :title="`学生销假-${title.stuName}(${this.title.grade}级${this.title.classesName})`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="500px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="销假时间" prop="verifyTime">
        <el-date-picker
          type="datetime"
          placeholder="销假时间"
          v-model="formData.verifyTime"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save(`<b>（销假时间：${formData.verifyTime}）</b>`,addLeave,'销假成功！')">销假
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addLeave } from '@/api/leave'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'verifyLeaveDialog',
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: null,
        verifyFlag: 1,
        verifyTime: '' // 销假时间
      },
      rules: {
        verifyTime: {
          required: true,
          message: '请选择销假时间',
          trigger: 'blur'
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.preTitle = `<span class="confirm-message">确认学生-<b class="primary">${this.title.stuName}</b>(${this.title.grade}级${this.title.classesName})已回校，并执行销假操作</span>`
      this.formData.id = this.id
      this.setInitData()
    })
  },
  methods: {
    addLeave // 保存api
  }
}
</script>
