<!--打印样式要写在 print/leaveTemp.html 的style内部-->
<template>
  <table class="table leave-temp" style="width: 100%;">
    <colgroup>
      <col style="width:120px;">
      <col>
      <col style="width:120px;">
      <col>
      <col style="width:120px;">
      <col>
    </colgroup>
    <tr>
      <td class="font-right" style="width: 120px;">辅导员审批</td>
      <td colspan="5">
        <print-signature-area
          :leave-data="leaveData"
          :status="'teacherStatus'"
          :opinion="'teacherOpinion'"
          :user-name="'teacherUserName'"
          :sign-time="'teacherTime'"
        />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <print-signature-area
          title="副院长审批："
          :leave-data="leaveData"
          :status="'collegeStatus'"
          :opinion="'collegeOpinion'"
          :user-name="'collegeUserName'"
          :sign-time="'collegeTime'"
        />
      </td>
      <td colspan="2">
        <print-signature-area
          title="学生处审批："
          :leave-data="leaveData"
          :status="'stuOfficeStatus'"
          :opinion="'stuOfficeOpinion'"
          :user-name="'stuOfficeUserName'"
          :sign-time="'stuOfficeTime'"
        />
      </td>
      <td colspan="2">
        <print-signature-area
          title="校领导审批："
          :leave-data="leaveData"
          :status="'schStatus'"
          :opinion="'schOpinion'"
          :user-name="'schUserName'"
          :sign-time="'schTime'"
        />
      </td>
    </tr>
    <tr>
      <td class="font-right">销假时间</td>
      <td colspan="2">
       <span v-if="leaveData.verifyTime" style="font-size: 15px;">{{ leaveData.verifyTime|formatDate(' YYYY 年 MM 月 DD 日  HH 时 mm 分')}}</span>
      </td>
      <td class="font-right">辅导员</td>
      <td colspan="2" style="font-size: 15px;">
        {{ leaveData.verifyFlag ? leaveData.teacherUserName : '' }}
      </td>
    </tr>
  </table>
</template>

<script>
import PrintSignatureArea from '@/views/pages/secondCollege/leave-out/component/printSignatureArea.vue'

export default {
  components: {PrintSignatureArea},
  props: {
    leaveData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
