import request from '@/service/request'

// 获取请假单列表
const getLeaveList = (params) => {
  return request.post('/stu/stuLeave/page', params)
}

// 审批请假单
const addLeave = (params) => {
  return request.post('/stu/stuLeave/save', params)
}

// 删除请假单
const deleteLeave = (params) => {
  return request.post('/stu/stuLeave/del/' + params)
}

// 根据id获取请假单信息
const getLeaveById = (id) => {
  return request.post(`/stu/stuLeave/getById/${id}`)
}

export const cancelReviewLeaveApi = (id) => {
  return request.post(`/stu/stuLeave/cancel/${id}`)
}

/* 批量审批
* @params {array} ids 请假单id
* @params {number} teacherStatus 辅导员角色审批意见 0驳回1通过
* @params {number} collegeStatus 院系角色审批意见 0驳回1通过
* @params {number} stuOfficeStatus  学生处角色审批意见 0驳回1通过
* @params {number} schStatus 校领导角色审批意见 0驳回1通过
* */
export const bathApproveApi = (params) => {
  return request.post('stu/stuLeave/verifyBatch', params)
}

export {
  getLeaveList,
  addLeave,
  deleteLeave,
  getLeaveById
}
