<template>
  <ul class="print-signature-area" style="font-size: 15px;">
    <li>
      {{ title }}<b v-if="leaveData[status]===0||leaveData[status]">{{ getReviewName(leaveData[status]) }}</b>
    </li>
    <li class="flex-1 oh" style="min-height: 100px;font-size: 15px;">{{ leaveData[opinion] }}</li>
    <li v-if="leaveData[userName]" style="text-align: right">
      <div style="margin-right: 53px">签名：{{ leaveData[userName] }}</div>
      <div  style="margin-right: 10px;font-size: 15px;"> {{ leaveData[signTime] | formatDate('YYYY 年 MM 月 DD 日') }}
      </div>
    </li>
    <li v-else style="text-align: right">
      <span style="margin-right: 100px">签名：</span><br />
      <span class="mr-1" style="font-size: 15px;">&#12288;&#12288;&#12288;&#12288;年&#12288;&#12288;月&#12288;&#12288;日</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'printSignatureArea',
  props: {
    title: String,
    opinion: String,
    status: String,
    userName: String,
    signTime: String,
    leaveData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  methods: {
    getReviewName (status) {
      if (status === 0 || status === '0') {
        return '审批不通过'
      } else if (status === 1 || status === '1') {
        return '审批通过'
      } else {
        return '待审批'
      }
    }
  }
}
</script>

<style lang="scss">
.print-signature-area{
  @include flex-column;
  justify-content: space-between;
}
</style>
